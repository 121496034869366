@use '@angular/material' as mat;
@import "./variables.scss";

@include mat.core();

html,body {
  margin:0;
  padding:0;
  font-family: var(--mat-toolbar-title-text-font);
  font-weight: 400;
  color: var(--primary-text-color);
  background: var(--page-background-color);
}
* {
  box-sizing: border-box;
}

.mat-drawer-container{
  background:none !important;
}

body{
  height: 100vh;
}


button {
  margin: 5px;
  height: 36px;
  width: 100px;
  font-size: 15px;
  border-radius: 3px;
  border: 0px;


  border: var(--secordary-button-border);
  background-color: var(--secondary-button-background-color);
  color: var(--secondary-button-text-color);

  &:hover {
    cursor: pointer;
  }
}

button[primary] {
  background-color: var(--primary-button-background-color);
  color: var(--primary-button-text-color);
  border: 0px;

  &:hover {
    background-color: var(--primary-button-hover-background-color);
    color: var(--primary-button-hover-text-color);
  }
}

.full-width {
  width: 100%;
}

table {
  background-color: unset !important;
  > thead {
    tr {
      color: var(--primary-color) !important;
    }
  }
  > tbody {
    overflow: auto;
    tr {
      &:hover {
        color: var(--row-text-hover) !important;
        background-color: var(--row-hover) !important;
        cursor: pointer;
      }
    }
  }

  &.no-pointer {
    > tbody {
      overflow: auto;
      tr {
        .bold {
          color: var(--accent-color);
        }
        &:hover {
          color: var(--row-text-hover) !important;
          background-color: var(--row-hover) !important;
          cursor:default;

          .bold {
            color: var(--row-text-hover);
          }
        }
      }
    }
  }
}

mat-divider {
  border-color: var(--border-color) !important;
  border: 0.5px solid !important;
}

mat-paginator {
  box-shadow: 0px -2px 10px 0px rgba(0, 0, 0, 0.2);
  background-color: var(--panel-background-color) !important;
}


.mat-drawer-inner-container {
  overflow: hidden;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled){
  background:none !important;
}

.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
  border: var(--default-border) !important;
}

.mat-mdc-snack-bar-container {
  &.snackbar-theme {
    --mdc-snackbar-container-color: var(--panel-background-color);
    --mat-snack-bar-button-color: var(--primary-color);
    --mdc-snackbar-supporting-text-color: var(--primary-color);
  }
}

.mat-mdc-standard-chip .mat-mdc-chip-action-label
{
  overflow: hidden !important;
}

.invalid {
  color: var(--invalid);
}

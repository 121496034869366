
:root {
  // Default Colors
  --white: #FFFFFF;
  --black: #000000;
  --clayton-bedtime: #06292F;
  --clayton-wet-cement: #51748B;
  --clayton-rubber-ducky: #FFC50A;
  --clayton-kiddie-pool: #00B0AC;
  --clayton-dusk: #8779CF;
  --background-dark: #1F1F1F;
  --off-white:#FAFAFA;
  --invalid: #911312;
  --disabled: #464646;
  --disabled-active: #2c2c2c;
  --clayton-gradiated-wet-cement: linear-gradient(180deg, #364e61 0%, rgba(23, 51, 64, 0) 100%), #173340;

  // Default styles
  --content-border: 0px;
  --page-background-color: #C4C4C4;
  --box-shadow:4px 0px 10px 0px rgba(0, 0, 0, 0.4);
  --border-radius: 5px;
  --border-color: rgba(0, 0, 0, 0.4);
  --primary-color: var(--clayton-wet-cement);
  --accent-color: var(--clayton-kiddie-pool);
  --primary-text-color: var(--black);
  --secondary-text-color: #6F6F6F;
  --logo-color: var(--white);
  --default-border: 0.6px solid var(--border-color);

// Panels
  --panel-background-color: #E8E8E8;
  --panel-heading-background-color: var(--primary-color);
  --panel-border-color: rbga(255,255,255, 0%);
  --panel-heading-text-color: var(--white);

// Tables
  --row-hover: var(--primary-color);
  --row-text: var(--primary-text-color);
  --row-secondary-text: var(--secondary-text-color);
  --row-text-hover: var(--white);
  --row-active-background: rgba(255,255,255,0);
  --row-active-border: var(--primary-color);
  --row-active-text: var(--white);

// Navigation
  --nav-menu-background: var(--clayton-gradiated-wet-cement);
  --nav-menu-border: 0px;
  --nav-item-background-hover: rgba(24, 89, 132, 0.2);
  --nav-item-active-border: 5px solid var(--nav-item-active-border-color);
  --nav-item-active-border-color: var(--off-white);
  --nav-item-text-color: var(--off-white);
  --nav-item-active-width: 162px;


  //Page Header
  --page-header-border: 0px;


  // Buttons
  --primary-button-background-color: var(--primary-color);
  --primary-button-text-color: var(--primary-text-color);

  --primary-button-hover-background-color: var(--primary-color-hover);
  --primary-button-hover-text-color: var(--white);

  --secondary-button-background-color: #949494;
  --secondary-button-text-color: var(--white);
  --secordary-button-border: var(--content-border);

  --button-disabled-color: #45454593;

  //menu
  --menu-box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.6);
  --menu-text-color: var(--primary-text-color)
  --menu-text-hover: var(--black)
  --menu-border: var(--content-border);

  //Modal
  --modal-window-background-color: rgba(0,0,0,0.6);
  --modal-box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.6);

  //Pills
  --pill-background: #F6F6F6;
  --pill-border: 1px solid rgba(255, 255, 255, 0.00);
  --pill-item-active: var(--primary-color);
  --pill-item-border: 1px solid var(--border-color);


  //Form Fields
  --form-field-border: 1px solid #4A4A4A;
}
